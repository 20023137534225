import '../../../application';
import {isMobile, prefersReducedMotion} from '@shopify/marketing-assets';

function init() {
  bioInformationReveal();
  heroParallax();
  charactersMovement();
  textReveal();
}

function bioInformationReveal() {
  const bioButtons = document.querySelectorAll('.js-otr-entrepreneurs-list-item-bio-button');

  bioButtons.forEach((bioButton) => {
    bioButton.setAttribute('aria-expanded', false);
    bioButton.addEventListener('click', () => {
      bioButton.parentElement.classList.toggle('js-otr-entrepreneurs-list-item');
      if (bioButton.parentElement.classList.contains('js-otr-entrepreneurs-list-item')) {
        bioButton.setAttribute('aria-expanded', true);
      } else {
        bioButton.setAttribute('aria-expanded', false);
      }
    });
  });
}

function charactersMovement() {
  if (isMobile || prefersReducedMotion() || !('IntersectionObserver' in window)) {
    return;
  }

  let heroAnimationLoopRef;
  let bottomAnimationLoopRef;

  const heroSection = document.querySelector('.js-otr-hero-section');
  const bottomSection = document.querySelector('.js-otr-empowering-section');

  const alondra = {
    element: document.querySelector('.js-otr-hero-image-alondra'),
    targetX: 0,
    targetY: 0,
    currentX: 0,
    currentY: 0,
    speed: 3.5,
  };

  const henry = {
    element: document.querySelector('.js-otr-hero-image-henry'),
    targetX: 0,
    targetY: 0,
    currentX: 0,
    currentY: 0,
    speed: 1.5,
  };

  const daniela = {
    element: document.querySelector('.js-otr-hero-image-daniela'),
    targetX: 0,
    targetY: 0,
    currentX: 0,
    currentY: 0,
    speed: 2,
  };

  const santosh = {
    element: document.querySelector('.js-otr-empowering-image-santosh'),
    targetX: 0,
    targetY: 0,
    currentX: 0,
    currentY: 0,
    speed: 1.5,
  };

  const jason = {
    element: document.querySelector('.js-otr-empowering-image-jason'),
    targetX: 0,
    targetY: 0,
    currentX: 0,
    currentY: 0,
    speed: 3,
  };

  const physics = {
    fraction: 0.2,
    vertical: 1.5,
  };

  const handleHeroCharactersMovement = (event) => {
    const mouseXPercentage = event.clientX / window.innerWidth;
    const mouseYPercentage = event.clientY / window.innerHeight * physics.vertical;
    [alondra.targetX, alondra.targetY] = [mouseXPercentage * alondra.speed, mouseYPercentage];
    [henry.targetX, henry.targetY] = [mouseXPercentage * henry.speed, mouseYPercentage];
    [daniela.targetX, daniela.targetY] = [mouseXPercentage * daniela.speed, mouseYPercentage];
  };

  const handleBottomCharactersMovement = (event) => {
    const mouseXPercentage = event.clientX / window.innerWidth;
    const mouseYPercentage = event.clientY / window.innerHeight * physics.vertical;
    [santosh.targetX, santosh.targetY] = [mouseXPercentage * santosh.speed, mouseYPercentage];
    [jason.targetX, jason.targetY] = [mouseXPercentage * jason.speed, mouseYPercentage];
  };

  const updateHeroMove = () => {
    [alondra, henry, daniela].forEach((item) => {
      item.currentX += (item.targetX - item.currentX) * physics.fraction;
      item.currentY += (item.targetY - item.currentY) * physics.fraction;
      item.element.style.transform = `translate(${item.currentX}vw, ${item.currentY}vh)`;
    });

    heroAnimationLoopRef = requestAnimationFrame(updateHeroMove);
  };

  const updateBottomMove = () => {
    [santosh, jason].forEach((item) => {
      item.currentX += (item.targetX - item.currentX) * physics.fraction;
      item.currentY += (item.targetY - item.currentY) * physics.fraction;
      item.element.style.transform = `translate(${item.currentX}vw, ${item.currentY}vh)`;
    });

    bottomAnimationLoopRef = requestAnimationFrame(updateBottomMove);
  };

  const observer = new IntersectionObserver((entries) => {
    // The nice thing is it only gets fired once every time the hero section intersects or not
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (entry.target.className.includes('js-otr-hero-section')) {
          document.addEventListener('mousemove', handleHeroCharactersMovement);
          updateHeroMove();
        } else if (entry.target.className.includes('js-otr-empowering-section')) {
          document.addEventListener('mousemove', handleBottomCharactersMovement);
          updateBottomMove();
        }
      }

      if (!entry.isIntersecting) {
        if (entry.target.className.includes('js-otr-hero-section')) {
          document.removeEventListener('mousemove', handleHeroCharactersMovement);
          cancelAnimationFrame(heroAnimationLoopRef);
        } else if (entry.target.className.includes('js-otr-empowering-section')) {
          document.removeEventListener('mousemove', handleBottomCharactersMovement);
          cancelAnimationFrame(bottomAnimationLoopRef);
        }
      }
    });
  });

  observer.observe(heroSection);
  observer.observe(bottomSection);
}

// text reveal

function textReveal() {
  const quote = document.querySelector(".otr-wall__quote");

  if (prefersReducedMotion() || !('IntersectionObserver' in window)) {
    quote.style.opacity = 1;
    quote.style.transform = "translateY(0)";
    return;
  }

  const observer = new IntersectionObserver((entries) => {
    // eslint-disable-next-line @shopify/prefer-early-return
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("js-otr-text-fade-in");
        observer.unobserve(quote);
      }
    });
  }, {
    threshold: 0.6,
  });

  observer.observe(quote);
}

// hero Parallax

function heroParallax() {
  if (!isMobile || prefersReducedMotion() || !('IntersectionObserver' in window)) {
    return;
  }

  let parallaxRequestAnimationRef;
  const heroSection = document.querySelector('.js-otr-hero-section');
  let currentScroll = 0;
  let aimScroll = 0;
  const damping = 0.15;
  const marginVelocity = 0.2;

  const updateScroll = () => {
    currentScroll += (aimScroll - currentScroll) * damping;
    if (currentScroll < 1) {
      currentScroll = 0;
    }
    // heroSection.style.marginTop = `-${currentScroll * marginVelocity}px`;
    heroSection.style.transform = `translate3d(0, ${currentScroll * marginVelocity}px, 0)`;

    parallaxRequestAnimationRef = requestAnimationFrame(updateScroll);
  };

  const handleScroll = () => {
    aimScroll = window.pageYOffset;
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        window.addEventListener("scroll", handleScroll);
        updateScroll();
      } else {
        window.removeEventListener("scroll", handleScroll);
        cancelAnimationFrame(parallaxRequestAnimationRef);
      }
    });
  });
  observer.observe(heroSection);
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', init);
} else {
  init();
}
